<template>
  <!-- Loading -->
  <!-- <LoadingComponent v-if="is_loading" /> -->
  <!-- Error -->
  <!-- <ErrorComponent v-else-if="error && !is_loading" :error="error" /> -->
  <!-- Data -->
  <div class="container">
    <div class="export-container">
      <button class="export-button" id="toEXCEL" @click="exportToExcel">
        تحميل ملف Exel
      </button>
    </div>
    <div class="table" id="customers_table">
      <div class="table__body">
        <table
          :style="{
            display: is_loading || results?.length === 0 ? 'flex' : '',
            justifyContent: is_loading || results?.length === 0 ? 'center' : '',
            alignItems: is_loading || results?.length === 0 ? 'center' : '',
            height: is_loading || results?.length === 0 ? '100%' : '',
          }"
        >
          <template v-if="is_loading">
            <div
              class="loading-container d-flex justify-content-center align-items-center"
              :class="{ active: is_loading }"
            >
              <div
                class="spinner-border"
                role="status"
                style="width: 4rem; height: 4rem"
                :style="{
                  color:
                    this.$store.state.darkMode === true ? '#fff' : '#1d4955',
                }"
              ></div>
            </div>
          </template>
          <template v-else-if="results?.length === 0 && !is_loading">
            <div
              class="d-flex justify-content-center align-items-center w-100 h-100"
            >
              <h1
                :style="{
                  color:
                    this.$store.state.darkMode === true ? '#fff' : '#1d4955',
                }"
              >
                لا توجد نتائج
              </h1>
            </div>
          </template>
          <template v-else>
            <thead>
              <tr>
                <th>التسلسل</th>
                <th>اسم الامتحان</th>
                <th>وصف الامتحان</th>
                <th>عدد الأسئلة</th>
                <th>النتيجة</th>
                <th>الحالة</th>
                <th>عدد الأسئلة المحلولة</th>
                <th>عدد الأسئلة الصحيحة</th>
                <th>الإجابات</th>
                <th>رقم الامتحان</th>
                <th>عدد المحاولات المتاحة</th>
                <th>عدد المحاولات المنتهية</th>
                <th>نسبة النجاح</th>
                <th>وقت بدأ الامتحان</th>
                <th>وقت انتهاء الامتحان</th>
                <th>وقت إنشاء الامتحان</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(result, index) in results" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ result?.exam_title }}</td>
                <td>{{ result?.exam_description }}</td>
                <td>{{ result?.exam_score }}</td>
                <td
                  :style="{
                    color:
                      result?.student_score !== 'not_allowed_yet'
                        ? result?.is_succeeded
                          ? '#2abf7a'
                          : '#e71c39'
                        : '',
                  }"
                >
                  {{
                    result?.student_score !== "not_allowed_yet"
                      ? result?.student_score
                      : "لم يتم السماح بعرض النتيجة"
                  }}
                </td>
                <td
                  :class="{
                    succeeded:
                      result?.is_succeeded &&
                      result?.student_score !== 'not_allowed_yet',
                    failed:
                      !result?.is_succeeded &&
                      result?.student_score !== 'not_allowed_yet',
                  }"
                >
                  <span
                    :class="{
                      succeeded:
                        result?.is_succeeded &&
                        result?.student_score !== 'not_allowed_yet',
                      failed:
                        !result?.is_succeeded &&
                        result?.student_score !== 'not_allowed_yet',
                    }"
                    v-if="result?.student_score !== 'not_allowed_yet'"
                  >
                    {{ result?.is_succeeded ? "ناجح" : "راسب" }}
                  </span>
                  {{
                    result?.student_score === "not_allowed_yet"
                      ? "لم يتم السماح بعرض النتيجة"
                      : ""
                  }}
                </td>
                <td>
                  {{
                    result?.number_of_questions -
                    result?.insolved_questions_count
                  }}
                </td>
                <td
                  :style="{
                    color:
                      result?.correct_questions_count !== 'not_allowed_yet'
                        ? '#e71c39'
                        : '',
                  }"
                >
                  {{
                    result?.correct_questions_count !== "not_allowed_yet"
                      ? result?.correct_questions_count
                      : "لم يتم السماح بعرض النتيجة"
                  }}
                </td>
                <td class="answers">
                  <router-link
                    v-if="result?.allowed_to_show_result"
                    :to="{
                      name: 'exam_answers',
                      params: { id: result?.exam_id },
                      query: { exam: result?.exam_title },
                    }"
                    class="available"
                    >عرض الإجابات</router-link
                  >
                  <span class="unavailable" v-else>الإجابات غير متاحة</span>
                </td>
                <td>{{ result?.exam_id }}</td>
                <td>{{ result?.number_of_allowed_trials }}</td>
                <td>{{ result?.trials }}</td>
                <td>{{ result?.passing_percent }}%</td>
                <td>{{ getHumanDate(result?.start) }}</td>
                <td>{{ getHumanDate(result?.end) }}</td>
                <td>{{ getHumanDate(result?.added_at) }}</td>
              </tr>
            </tbody>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import exportToExcel from "../../../services/csv";
import config from "@/services/config";
export default {
  name: "AssignmentResultsComponent",
  components: {},
  data() {
    return {
      results: [],
      is_loading: true,
    };
  },
  methods: {
    exportToExcel() {
      exportToExcel();
    },
    get_results() {
      axios
        .get(`${config.baseURL}exams/exam-results/?exam__related_to=LESSON`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.results = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    getHumanDate(date) {
      return new Date(date).toLocaleDateString("ar-EG", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  created() {
    this.get_results();
  },
};
</script>

<style scoped>
.export-container {
  text-align: center;
  margin-bottom: 2rem;
}
.export-button {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 2px 0px #000000;
  padding: 1rem 2rem;
  border-radius: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s;
}
.export-button:hover {
  background-color: #038c4c;
  color: #fff;
}
.table > :not(caption) > * > * {
  background-color: transparent;
}
.table {
  position: relative;
  width: 100%;
  height: 75vh;
  background-color: #fff;
  box-shadow: 0 0.4rem 0.8rem #0005;
  border-radius: 1.25rem;
  overflow: hidden;
  padding: 2rem;
  direction: ltr;
}
.table__body {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  padding: 0 2rem 2rem 2rem;
  overflow: auto;
  overflow: overlay;
}
.table__body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.table__body::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #c3c3c3;
  border: 1px solid #c3c3c3;
}
.table__body::-webkit-scrollbar-track {
  background-color: #eeeeee;
}
table {
  width: 100%;
  direction: rtl;
}
table,
th,
td {
  border-collapse: collapse;
  padding: 1rem 0.5rem;
  text-align: center;
  min-width: 190px;
  min-height: 31px;
}
thead th {
  position: sticky;
  top: 0;
  /* left: 0; */
  z-index: 1;
  background-color: #fff;
  color: #000;
  border: none;
  border-bottom: 0.1rem solid #00000020;
}
tbody tr {
  background-color: #fff;
  border: none;
  border-bottom: 0.1rem solid #00000010;
}
th {
  color: #000;
  font-weight: 700;
}
td {
  color: #000000;
  font-weight: 500;
}
span.succeeded,
span.failed,
.unavailable,
.available {
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  width: 100%;
  display: inline-block;
  color: #fff !important;
  text-decoration: none;
}
span.succeeded,
.available {
  background-color: #2abf7a;
  cursor: pointer;
}
span.failed,
.unavailable {
  background-color: #e71c39;
}
/* Dark Mode */
body.dark th {
  color: #fff;
}
body.dark .export-button {
  background-color: #1d4955;
  color: #fff;
}
body.dark .export-button:hover {
  background-color: #038c4c;
}
body.dark .table__body::-webkit-scrollbar-thumb {
  background-color: #235562;
  border: 1px solid #13323a;
}
body.dark .table__body::-webkit-scrollbar-track {
  background-color: #13323a;
}
body.dark tbody tr,
body.dark .table__body,
body.dark .table {
  background-color: #1d4955;
}
body.dark thead th {
  background-color: #1d4955;
  color: #fff;
  border: none;
  border-bottom: 0.1rem solid #ffffff20;
}
body.dark td {
  color: #ffffff;
}
body.dark .unavailable,
body.dark .available {
  color: #1d4955 !important;
}
body.dark .available {
  background-color: #31d287;
}
body.dark .unavailable {
  background-color: #e9334e;
}
/*  Media queries */
@media screen and (max-width: 992px) {
  .export-button {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
  }
  .table {
    padding: 1rem;
  }
  .table__body {
    padding: 0 1rem 1rem 1rem;
  }
  th,
  td {
    font-size: 0.9rem;
  }
}
</style>
