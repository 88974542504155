<template>
  <div class="user-profile">
    <!--  user info -->
    <div class="header header-user-profile mean-header">
      <h1>ملف المستخدم</h1>
    </div>
    <div class="container container-component px-lg-5">
      <user-info-component
        :data="user_data"
        :refresh="get_profile_data"
      ></user-info-component>
    </div>
    <!-- Courses statistics -->
    <div class="header header-courses-statistics sub-header">
      <h1>احصائيات كورساتك</h1>
    </div>
    <div class="container container-component px-lg-5">
      <CoursesStatisticsComponent
        :analysis_data="analysis_data"
        :is_loading="is_loading"
      />
    </div>
    <!-- Platform statistics -->
    <!-- <div class="header header-platform-statistics sub-header">
      <h1>احصائياتك على المنصة</h1>
    </div>
    <div class="container container-component px-lg-5">
      <platform-statistics-component></platform-statistics-component>
    </div> -->
    <div class="header header-platform-statistics sub-header">
      <h1>تحديث المنصة</h1>
    </div>
    <div class="container d-flex justify-content-center align-items-center">
      <button class="reload-clear-cache" @click="update_clear_cache">
        <ClArrowsReload01/>
        تحديث
      </button>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

const cache = reactive({});
import { ClArrowsReload01 } from '@kalimahapps/vue-icons';
import axios from "axios";
import {
  UserInfoComponent,
  CoursesStatisticsComponent,
  // PlatformStatisticsComponent,
} from "../../components";
import config from "@/services/config";

export default {
  name: "UserProfileView",
  data() {
    return {
      user_data: {},
      analysis_data: {},
      is_loading: true,
    };
  },
  components: {
    UserInfoComponent,
    CoursesStatisticsComponent,
    ClArrowsReload01
    // PlatformStatisticsComponent,
  },
  created() {
    if (cache["profile_data"]) {
      this.user_data = cache["profile_data"];
    } else {
      this.get_profile_data();
    }
    this.get_data();
  },
  methods: {
    update_clear_cache(){
      window.location.href = window.location.pathname + "?nocache=" + new Date().getTime();
    },
    get_profile_data() {
      this.user_data = {};
      axios
        .get(`${config.baseURL}student/student-profile`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          cache["profile_data"] = { ...res.data };
          this.user_data = { ...res.data };
          this.$store.commit("SET_STUDENT_NAME", res.data.name);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    get_data() {
      axios
        .get(`${config.baseURL}analysis/profile-analysis`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          // courses progress
          res.data.progress_in_all_courses.progress =
            res.data.progress_in_all_courses.total_lessons == 0
              ? 0
              : Math.floor(
                  (res.data.progress_in_all_courses.watched_lessons /
                    res.data.progress_in_all_courses.total_lessons) *
                    100
                );

          // exams progress
          res.data.exam_progress.progress =
            res.data.exam_progress.total_exams_should_be_taken == 0
              ? 0
              : Math.floor(
                  (res.data.exam_progress.total_exams_taken /
                    res.data.exam_progress.total_exams_should_be_taken) *
                    100
                );

          // average
          res.data.exam_progress.average =
            res.data.exam_progress.total_exam_scores == 0
              ? 0
              : Math.floor(
                  (res.data.exam_progress.total_student_scores /
                    res.data.exam_progress.total_exam_scores) *
                    100
                );

          this.analysis_data = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style scoped>
.user-profile {
  min-height: calc(100vh - 135px);
  background-color: #f9f9f9;
  padding: 4rem 0;
}

.user-profile .header {
  text-align: center;
  position: relative;
  margin: 2rem 0 6rem 0;
}

.user-profile .header::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #00000050;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 0;
  transform: translate(0, -100%);
}

.user-profile .header.sub-header::before {
  width: 90%;
  left: 5%;
}

.user-profile .header.sub-header {
  margin: 10rem 0 7rem 0;
}

.user-profile .header h1 {
  margin: 0 auto;
  text-align: center;
  border: 1px solid #00000050;
  color: #000;
  background-color: #f9f9f9;
  font-size: 1.25rem;
  font-weight: 500;
  width: fit-content;
  padding: 1rem 5.5rem;
  border-radius: 37px;
  position: relative;
  z-index: 1;
}

.user-profile .header.sub-header h1 {
  background-color: #f9f9f9;
  border-radius: none;
  border: none;
}

/* Dark mode */
body.dark .user-profile {
  background-color: #13323a;
}

body.dark .user-profile .header::before {
  background-color: #ffffff;
}

body.dark .user-profile .header h1 {
  color: #fff;
  background-color: #1d4955;
  border: 1px solid #ffffff50;
}

body.dark .user-profile .header.sub-header h1 {
  background-color: #13323a;
  border: none;
  border-radius: none;
}

/*  Media queries */
@media screen and (max-width: 992px) {
  .container-component {
    max-width: 100%;
    padding: 0;
  }

  .user-profile .header.sub-header::before {
    width: 100%;
    left: 0;
  }

  .user-profile .header h1 {
    padding: 1rem 3rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .user-profile .header h1 {
    padding: 1rem 1.5rem;
    font-size: 0.875rem;
  }
}
.reload-clear-cache {
  all: unset;
  padding: 0.5rem 1.5rem;
  background-color:#c0392b;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
}
.reload-clear-cache:hover {
  background-color:#c0392b90;
}
</style>
