<template>
  <div class="course-card" v-if="data">
    <div class="course-card-header">
      <img
        :src="subscribed ? data?.course__cover : data?.cover"
        alt="course image"
        class="course-image"
        loading="lazy"
      />
      <div v-if="!subscribed">
        <span v-if="!data?.has_subscription" class="lock">
          <FeLock />
        </span>
        <span v-else class="unlock">
          <FeUnlock />
        </span>
      </div>
    </div>
    <div class="course-card-body-header">
      <h3 class="course-card-title">
        {{ subscribed ? data?.course__name : data?.name }}
      </h3>
    </div>
    <div class="course-card-body">
      {{ subscribed ? data?.course__description : data?.description }}
    </div>
    <div class="w-100">
      <div class="date-price-container">
        <span class="date"> {{ getHumanDate(data?.created) }}</span>
        <span class="price justify-content-end" v-if="!data?.free">
          {{ data?.price }}
          <IoOutlinePricetag />
        </span>
        <span class="price free-text" v-if="data?.free"> مجاني </span>
      </div>
      <div class="price free" v-if="data?.free">
        {{ data?.price }}
        <IoOutlinePricetag />
      </div>
    </div>
    <router-link
      v-if="subscribed || data?.has_subscription"
      :to="{
        name: 'course_content',
        params: { id: data?.course_id },
        query: { subscribed: subscribed },
      }"
      class="course-card-footer"
    >
      الدخول للكورس
    </router-link>
    <div v-else class="footer un-subscribed">
      <router-link
        :to="{
          name: 'payment',
          params: { id: data?.course_id },
          query: { free: data?.free },
        }"
        @click="setPaymentID(data?.id)"
        class="course-card-footer"
      >
        الاشتراك
      </router-link>
      <router-link
        :to="{ name: 'course_content', params: { id: data?.course_id } }"
        class="course-card-footer go-to-course"
      >
        الدخول للكورس
      </router-link>
      <span class="add-to-cart">
        <FeShoppingBag />
      </span>
    </div>
    <!-- <div class="w-100" v-if="data?.has_subscription == false && data?.free == false">
      <button v-if="!is_activating" @click="activate_course_by_code" class="course-card-footer w-100"> تفعيل الكورس
        يالكود </button>
      <button v-else disabled class="course-card-footer w-100"> <span class="spinner-border"></span> </button>
    </div> -->
  </div>
</template>

<script>
import config from "@/services/config";
import {
  FeShoppingBag,
  FeLock,
  FeUnlock,
  IoOutlinePricetag,
} from "@kalimahapps/vue-icons";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "CourseCardComponent",
  components: {
    FeShoppingBag,
    FeLock,
    FeUnlock,
    IoOutlinePricetag,
  },
  data() {
    return {
      is_activating: false,
    };
  },
  props: ["subscribed", "data"],
  methods: {
    getHumanDate(d) {
      const date = new Date(d);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return date.toLocaleDateString("ar", options);
    },
    setPaymentID(id) {
      if (!this.$store.userToken) {
        this.$store.commit("SET_PAYMENT_ID", id);
      }
    },
    activate_course_by_code() {
      Swal.fire({
        title: "اكتب كود تفعيل الكورس",
        input: "text",
        imageUrl: this.data?.cover,
        imageHeight: "200",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "تفعيل",
        cancelButtonText: "الغاء",
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          if (login == null || login.length == 0) {
            Swal.fire({
              text: "اكتب الكود عشان تعرف تفعل الكورس",
              icon: "warning",
            });
            return;
          }

          this.is_activating = true;
          axios
            .post(
              `${config.baseURL}codes/active-course-code`,
              {
                course_id: this.data?.course_id,
                course_code: login,
              },
              {
                headers: {
                  auth: this.$store.state.userToken,
                },
              }
            )
            .then(() => {
              Swal.fire({
                text: "تم تفعيل الكورس بنجاح هتلاقي الكورس دلوقتي في كورساتي ",
                icon: "success",
              });
              this.$router.push({ name: "user_courses" });
            })
            .catch((err) => {
              console.log(err);
              if (err.response.status == 400) {
                Swal.fire({
                  text: err.response.data.detail,
                  icon: "error",
                });
              } else {
                Swal.fire({
                  text: "حدث خطاء",
                  icon: "error",
                });
              }
            })
            .finally(() => {
              this.is_activating = false;
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.course-card {
  width: calc(100% / 3 - 1rem);
  max-width: 100%;
  min-height: 550px;
  max-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 1rem 0.75rem;
  border: 0.62px solid #148753;
  border-radius: 2rem;
  transition: all 0.2s ease-out;
}

.course-card:hover {
  width: calc(100% / 3);
  scale: 1.03;
  transition: all 0.2s ease-out;
}

.course-card-header {
  width: 100%;
  /* max-height: max-content; */
  /* min-height: auto; */
  height: 250px;
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.unlock,
.lock {
  background-color: #000000a1;
  color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 50%;
  font-size: 1.5rem;
  transform: translate(50%, -50%);
  border-radius: 1.25rem;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.course-card:hover .unlock,
.course-card:hover .lock {
  opacity: 1;
}

.course-image {
  width: 100%;
  height: 100%;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
  width: 100%;
  height: 100%;
  /* height: auto; */
  border-radius: 20px;
  -o-object-fit: cover;
  object-fit: fill;
}

.course-card-title span,
.course-card-title {
  font-weight: 500;
  color: #000;
}

.course-card-title {
  width: 100%;
  font-size: 1.75rem;
  text-align: right;
  margin-bottom: 0;
}

.course-card-title span {
  font-size: 1.25rem;
}

.date {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
}

.course-card-body {
  background-color: #13323a;
  background-color: #f3f3f3;
  width: 100%;
  height: 100px;
  max-height: 100px;
  border-radius: 20px;
  padding: 1rem;
  overflow-y: scroll;
  scrollbar-width: none;
}

.course-card-body-header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
}

.course-item {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.course-item:last-child {
  margin-bottom: 0;
}

.course-item img {
  width: 80px;
  height: 45px;
  border-radius: 10px;
  object-fit: cover;
}

.lecture-name {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.lecture-name span {
  font-weight: 300;
}

.course-card-footer {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #038c4c;
  color: #fff;
  border: 1px solid #038c4c;
  padding: 0.5rem;
  border-radius: 22px;
  font-size: 13.5px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.course-card-footer:hover {
  background-color: transparent;
  color: #038c4c;
}

body.dark .footer.un-subscribed .course-card-footer.go-to-course {
  background-color: #038c4c;
  color: #fff;
  border: 1px solid #038c4c;
}

.footer.un-subscribed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.footer.un-subscribed .course-card-footer,
.footer.un-subscribed .course-card-footer.go-to-course {
  /* width: calc(50% - 20px - 0.5rem); When Cart btn is not hidden */
  width: calc(50% - 0.5rem);
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.footer.un-subscribed .course-card-footer {
  background-color: #0099ff;
  color: #ffffff;
  border: 0.95px solid #0090ff;
}

.footer.un-subscribed .course-card-footer:hover {
  background-color: transparent;
  color: #0090ff;
}

.footer.un-subscribed .course-card-footer.go-to-course {
  background-color: #038c4c;
  color: #fff;
  border: 1px solid #038c4c;
}

.footer.un-subscribed .course-card-footer.go-to-course:hover {
  background-color: transparent;
  color: #038c4c;
}

.add-to-cart {
  background-color: #f3f3f3;
  color: #000000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
  display: none;
}

.add-to-cart:hover {
  background-color: #1e1e1e;
  color: #fff;
}

.date-price-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-price-container > * {
  width: calc(95% / 2);
}

.price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #000000;
}
.price.free {
  width: 100%;
  text-decoration: line-through;
  font-size: 1rem;
  color: #00000080;
}
body.dark .price.free {
  color: #ffffff80;
}
.free-text {
  color: #219921 !important;
}
body.dark .free-text {
  color: #19ff19 !important;
}

body.dark .price {
  color: #ffffff;
}

/* Dark Mode */
body.dark .course-card {
  background-color: #1d4955;
  border: 0.62px solid #179d61;
}

body.dark .course-card-title span,
body.dark .course-card-title {
  color: #ffffff;
}

body.dark .course-card-body {
  background-color: #13323a;
  color: #fff;
}

body.dark .course-card-footer:hover,
body.dark .lecture-name {
  color: #fff;
}

body.dark .date {
  color: #ffffff;
}

body.dark .footer.un-subscribed .course-card-footer {
  background-color: #1aa3ff;
  color: #ffffff;
  border: 0.95px solid #1a9cff;
}

body.dark .footer.un-subscribed .course-card-footer:hover {
  background-color: transparent;
  color: #ffffff;
}

body.dark .add-to-cart {
  background-color: #13323a;
  color: #ffffff;
}

body.dark .add-to-cart:hover {
  background-color: #1e1e1e;
  color: #fff;
}

/* Media queries */
@media screen and (max-width: 992px) {
  .course-card {
    width: calc(100% / 2 - 1rem);
  }

  .course-card:hover {
    width: calc(100% / 2);
    scale: 1.01;
  }

  .course-card-body {
    padding: 1rem 0.5rem;
  }

  .course-card-title {
    font-size: 1.5rem;
    text-align: start;
  }

  .course-card-title span {
    font-size: 1rem;
  }

  .date {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  .course-card {
    width: 100%;
    /* min-height: 900px; */
    /* max-height: 900px; */
  }
  .course-card-header {
    /* max-height: 850px; */
    /* min-height: 850px; */
    /* height: 850px; */
  }
  .course-image {
    /* width: 100%;
    height: auto; */
  }

  .course-card:hover {
    width: 100%;
  }

  .course-card-title {
    font-size: 1.25rem;
  }

  .course-card-title span,
  .date {
    font-size: 0.75rem;
  }

  .course-item img {
    width: 60px;
    height: 35px;
  }

  .lecture-name {
    font-size: 10px;
  }

  .footer.un-subscribed {
    gap: 0.5rem;
  }
}
@media screen and (max-width: 576px) {
  .course-card {
    /* min-height: 850px; */
    /* max-height: 850px; */
  }
}
@media screen and (max-width: 400px) {
  .course-card {
    /* min-height: 700px; */
    /* max-height: 700px; */
  }
  .course-image {
    object-fit: cover;
  }
}
</style>
