<template>
  <div class="item d-flex align-items-center justify-content-between">
    <div class="content-info">
      <div class="d-flex justify-content-start">
        <div class="icon" :class="{ file: content?.content_type == 'file' }">
          <BxBook v-if="content?.content_type == 'lesson'" />
          <AkFile v-if="content?.content_type == 'file'" />
          <HeOutlineIExamQualification v-if="content?.content_type == 'exam'" />
        </div>
        <p>
          {{ content?.name || content?.title }}
          {{ content?.content_type == "exam" ? " (اختبار) " : "" }}
        </p>
      </div>
      <p v-if="content?.content_type == 'lesson'" class="desc">
        {{ content?.description }}
      </p>
    </div>
    <div v-if="is_auth && has_subscription" class="operations">
      <router-link
        v-if="content?.content_type == 'lesson'"
        class="lesson"
        :to="{
          name: 'lesson',
          params: { course_id: course_id, lesson_id: content?.id },
        }"
      >
        دخول الدرس
      </router-link>
      <button
        @click="get_file"
        class="file"
        v-if="content?.content_type == 'file' && !file_load"
        href=""
      >
        دخول الفايل
      </button>
      <button class="file" v-if="content?.content_type == 'file' && file_load" href="">
        <span class="spinner-border"></span>
      </button>
      <router-link
        v-if="content?.content_type == 'exam'"
        class="exams"
        :to="{
          name: 'startExam',
          params: { exam_id: content?.id, exam_name: content?.title },
        }"
      >
        دخول الاختبار
      </router-link>
    </div>
  </div>
</template>
<script>
import config from "@/services/config";
import {
  BxBook,
  AkFile,
  HeOutlineIExamQualification,
} from "@kalimahapps/vue-icons";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "LessonBoxComponent",
  props: ["content", "has_subscription", "course_id"],
  components: {
    BxBook,
    AkFile,
    HeOutlineIExamQualification,
  },
  data() {
    return {
      is_auth: false,
      file_load: false,
    };
  },
  created() {
    if (this.$store.state.userToken) {
      this.is_auth = true;
    }
  },
  methods: {
    get_file() {
      this.file_load = true;
      axios
        .get(
          `${config.baseURL}subscription/access-content/${this.course_id}/file/${this.content.id}`,
          {
            headers: {
              auth: this.$store.state.userToken,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          window.open(res.data.file, "_blank");
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            text: "حدث خطاء اثناء تحميل الفيديو حاول مره اخري لاحقا",
            icon: "error",
          });
        })
        .finally(() => {
          this.file_load = false;
        });
    },
  },
};
</script>
<style scoped>
.item {
  background-color: #f9f9f9;
  border-bottom: 1px solid #00000033;
  border-radius: 0.5rem;
  padding: 0.8rem 0.3rem;
}
.item .content-info {
  /* display: flex; */
  /* align-items: center; */
  font-size: 1.5rem;
}
.item .content-info .icon {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9747ff33;
  border-radius: 50%;
  margin-left: 0.8rem;
  /* padding: 0.3rem; */
}
.item .content-info .icon svg {
  color: #9747ff;
}
.item .content-info .icon.file {
  background-color: #d9f0ff !important;
}
.item .content-info .icon.file svg {
  color: #0278ff !important;
}
.item .content-info p {
  margin: 0 0.5rem 0 0;
}
.item .content-info p:first-of-type {
  margin-left: 1rem;
  position: relative;
}
.item .content-info p:first-of-type::after {
  content: "";
  position: absolute;
  top: 0;
  left: -0.9rem;
  width: 1px;
  height: 100%;
  background-color: #00000033;
}
.item .content-info p:first-of-type:not(:has(+ *))::after {
  display: none;
}
.item .content-info p.desc {
  font-size: 0.9rem;
  color: #00000099;
  margin-top: 1rem;
}
body.dark .item .content-info p.desc {
  color: #ffffff99;
}
.operations {
  padding: 0 0.5rem;
}
.operations a,
.operations button {
  all: unset;
  padding: 0.4rem 0.5rem;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
}
.operations a.lesson {
  background-color: #e71c39;
  color: #fff;
}
.operations button.file {
  background-color: #eaeaea;
  color: #000;
}
a.exams {
  background-color: #038c4c;
  color: #fff;
}

body.dark .item {
  background-color: #13323a !important;
  color: #ffff !important;
}

@media (max-width: 767px) {
  .item {
    flex-direction: column;
  }
  .item .content-info,
  .item .operations {
    width: 100%;
  }
  .item .content-info p {
    font-size: 1rem;
  }
  .item .content-info {
    margin-bottom: 0.8rem;
  }
  .item .operations a,
  .item .operations button {
    width: 100% !important;
    display: block;
  }
}
</style>
