<template>
  <div class="course-content-container mb-3">
    <CourseContentHeaderComponent
      :course="course"
      :getHumanDate="getHumanDate"
    />
    <div class="body">
      <div class="head">
        <h2>محتوى الكورس</h2>
      </div>
      <div class="content">
        <div class="download-app">
          <h3>قم بتحميل التطبيق لتتمكن من مشاهدة الكورس</h3>
          <a
            class="go-to-forum-btn"
            :href="downloadLink"
            target="_blank"
            rel="noopener noreferrer"
            role="button"
          >
            <AkDownload />
            حمل التطبيق
          </a>
        </div>
        <div
          class="accordion accordion-flush"
          :id="'accordionFlushExample' + index"
        >
          <UnitBoxComponent
            v-for="(unit, index) in units"
            :course_id="id"
            :key="index"
            :unit="unit"
            :index="index"
            :has_subscription="course?.has_subscription"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import axios from "axios";
import { AkDownload } from "@kalimahapps/vue-icons";

import CourseContentHeaderComponent from "@/components/CourseContentHeader/CourseContentHeaderComponent.vue";
import UnitBoxComponent from "@/components/UnitBox/UnitBoxComponent.vue";
import { watchEffect } from "vue";
import Swal from "sweetalert2";
export default {
  name: "CourseContentView",
  components: {
    CourseContentHeaderComponent,
    UnitBoxComponent,
    AkDownload,
  },
  props: ["id"],
  data() {
    return {
      course: {},
      units: [],
      downloadLink: "",
    };
  },
  mounted() {
    watchEffect(() => {
      this.downloadLink = navigator.userAgent.includes("Android")
        ? "https://play.google.com/store/apps/details?id=com.easytech.easy_player_app&pcampaignid=web_share"
        : navigator.userAgent.includes("iOS") ||
          navigator.userAgent.includes("iPhone")
        ? "https://apps.apple.com/eg/app/easy-player/id6739153042"
        : navigator.userAgent.includes("Win")
        ? //  ||----
          //   navigator.userAgent.includes("Mac") ||
          //   navigator.userAgent.includes("Linux")
          "https://d2pez1z0slib5f.cloudfront.net/easyplayer.exe"
        : Swal.fire({
            title: "تنبيه",
            text: "التطبيق متاح فقط على Android و iOS و Windows",
            icon: "warning",
            confirmButtonText: "حسنا",
          });
    });
  },
  methods: {
    getCourse() {
      axios
        .get(`${config.baseURL}course/course-details/${this.id}`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          this.course = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getUnit() {
      axios
        .get(`${config.baseURL}course/unit-list/${this.$route?.params?.id}`)
        .then((res) => {
          this.units = res.data;
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getHumanDate(d) {
      const date = new Date(d);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return date.toLocaleDateString("ar", options);
    },
  },
  created() {
    this.getCourse();
    this.getUnit();
  },
};
</script>

<style scoped>
.download-app {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e74d3c39;
  border: 1px solid #e74c3c;
  padding: 0.75rem 1rem;
  margin: 0;
  color: #e74c3c;
  width: 100%;
  margin: 2rem 0;
  border-radius: 1rem;
}
.download-app h3 {
  font-size: 1.25rem;
  color: #e74c3c;
  font-weight: 900;
  margin: 0;
}
.download-app .go-to-forum-btn {
  padding: 0.5rem 2rem;
  min-width: 100px;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  outline: none;
  border-radius: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 900;
  background-color: #e74d3c;
  border: 1px solid #e74d3c;
  transition: all 0.3s ease-in-out;
}
.download-app .go-to-forum-btn:hover {
  background-color: #ffffff;
  color: #e74d3c;
  transition: all 0.3s ease-in-out;
}
.body {
  max-width: calc(100% - 2rem);
  background-color: #ffffff;
  border: 1px solid #00000033;
  border-radius: 3rem;
  margin: 3.5rem auto;
  padding: 3rem 1rem;
  position: relative;
}

.body .head h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 1.25rem;
  font-weight: 500;
  color: #000000;
  margin: 0;
  padding: 0.75rem 2.5rem;
  border-radius: 3rem;
  background-color: #ffffff;
  border: 1px solid #00000033;
}

.accordion-button {
  border-width: 0.5px 0.5px 5px 0.5px !important;
  border-style: solid !important;
  border-color: #000000 !important;
  border-radius: 1rem !important;
  overflow: hidden;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #000000 !important;
}

.accordion-button::after {
  margin-left: 0 !important;
  background-image: var(--bs-accordion-btn-icon) !important;
}
body.ark .accordion-button::after {
  margin-left: 0 !important;
  --bs-accordion-btn-icon: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e" !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-collapse {
  margin-top: 1rem !important;
  border: 1px solid #00000033 !important;
  border-radius: 1rem !important;
}

.body .item.lecture,
.body .item.assignment .con {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.body .item.lecture {
  background-color: #ffffff;
}

.body .item.assignment {
  background-color: #f9f9f9;
  border-bottom: 1px solid #00000033;
}

.body .item .right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.body .item.assignment .right .icon,
.body .item .right .icon {
  background-color: #9747ff33;
  color: #9747ff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 1.1rem;
  border-radius: 50%;
}

.body .item .right .icon {
  background-color: #9747ff33;
  color: #9747ff;
}

.body .item.assignment .right .icon {
  background-color: #d9f0ff;
  color: #0278ff;
}

.body .item .right .title-lecture {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.body .item .right .title-lecture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.body .item .right .separator {
  height: 20px;
  border-left: 1px solid #00000033;
}

.body .item .right .sub-description {
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.body .item .right .chapter {
  font-size: 1rem;
  font-weight: 500;
  color: #ffffff;
  padding: 0.25rem 2rem;
  border-radius: 2.5rem;
}

.body .item .right .chapter.type {
  background-color: #0278ff;
}

.body .item .right .chapter {
  background-color: #038c4c;
}

.body .item .left {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.body .item .left button {
  padding: 0.25rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 2.5rem;
  cursor: pointer;
}

.body .item .left .open-file-btn {
  background-color: #eaeaea;
  color: #000000;
}

.body .item.assignment .left .open-assignment-btn {
  background-color: #d9f0ff;
  color: #000000;
}

.body .item .left .watch-video {
  background-color: #e71c39;
  color: #ffffff;
}

.body .item.assignment .center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.body .item.assignment .center .questions,
.body .item.assignment .center .time {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.body .item.assignment .center .questions-title,
.body .item.assignment .center .time-title {
  font-size: 1rem;
  font-weight: 500;
  color: #000000cc;
}

.body .item.assignment .center .questions-count,
.body .item.assignment .center .time-count {
  font-size: 1rem;
  font-weight: 500;
  color: #00000080;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.accordion-item {
  border: none !important;
  margin-bottom: 1rem !important;
}

/* Dark Mode */
body.dark .body {
  background-color: #13323a;
  border: 1px solid #ffffff33;
}

body.dark .body .head h2 {
  background-color: #13323a;
  color: #ffffff;
  border: 1px solid #ffffff33;
}

body.dark .body .accordion-item {
  background-color: #13323a;
}

body.dark .body .accordion-button {
  background-color: #13323a;
  color: #ffffff !important;
  border-color: #1d4955 !important;
}

body.dark .accordion-button::after,
body.dark .accordion-button:not(.collapsed)::after {
  --bs-accordion-btn-icon: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e" !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
body.dark .accordion-button::after {
  margin-left: 0 !important;
  --bs-accordion-btn-icon: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e" !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

body.dark .body .item.lecture {
  background-color: #1d4955;
}

body.dark .body .item.assignment {
  background-color: #13323a;
  border-bottom: 1px solid #ffffff33;
}

body.dark .body .item .right .icon {
  background-color: #a561ff33;
  color: #a561ff;
}

body.dark .body .item.assignment .right .icon {
  background-color: #00263f;
  color: #1c86ff;
}

body.dark .body .item .right .title-lecture-container,
body.dark .body .item .right .sub-description {
  color: #ffffff;
}

body.dark .body .item .right .chapter {
  background-color: #04a55a;
  color: #000000;
}

body.dark .body .item .right .chapter.type {
  background-color: #1c86ff;
  color: #000000;
}

body.dark .body .item .left .open-file-btn {
  background-color: #2e5560;
  color: #ffffff;
}

body.dark .body .item .left .watch-video {
  background-color: #e9334e;
  color: #ffffff;
}

body.dark .body .item.assignment .left .open-assignment-btn {
  background-color: #00263f;
  color: #ffffff;
}

body.dark .body .item.assignment .center svg,
body.dark .body .item.assignment .center svg,
body.dark .body .item.assignment .center .questions-title,
body.dark .body .item.assignment .center .time-title {
  color: #ffffffcc;
}

body.dark .body .item.assignment .center .questions-count,
body.dark .body .item.assignment .center .time-count {
  color: #ffffff80;
}

/* Media Queries */
@media (max-width: 1200px) {
  .download-app h3 {
    font-size: 1rem;
  }
  .body {
    max-width: 100%;
    margin: 2rem;
    padding: 2rem 1rem;
  }

  .body .head h2 {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }

  .body .item .right .title-lecture-container {
    font-size: 1rem;
  }

  .body .item .right .sub-description {
    font-size: 0.8rem;
  }

  .body .item .right .chapter {
    font-size: 0.8rem;
    padding: 0.25rem 1rem;
  }

  .body .item .left button {
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
  }

  .body .item .right .icon,
  .body .item.assignment .right .icon {
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
  }

  .body .item .right .separator {
    height: 15px;
  }

  .body .item .right .chapter.type {
    padding: 0.25rem 1rem;
  }

  .body .item .right .chapter {
    padding: 0.25rem 1rem;
  }

  .body .item .left .open-file-btn {
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
  }

  .body .item.assignment .left .open-assignment-btn {
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
  }

  .body .item .left .watch-video {
    padding: 0.25rem 1rem;
    font-size: 0.8rem;
  }

  .body .item.assignment .center .questions-title,
  .body .item.assignment .center .time-title {
    font-size: 0.8rem;
  }

  .body .item.assignment .center .questions-count,
  .body .item.assignment .center .time-count {
    font-size: 0.8rem;
  }
}

@media (max-width: 992px) {
  .body .item .right .title-lecture-container {
    font-size: 1.1rem;
  }

  .body .item .right .title-lecture {
    flex-direction: column;
    align-items: flex-start;
  }

  .body .item .right .title-lecture .separator {
    display: none;
  }

  .body .item .right .sub-description {
    font-size: 0.9rem;
  }

  .body .item .right .chapter {
    font-size: 0.9rem;
    padding: 0.25rem 1.5rem;
  }

  .body .item .left button {
    padding: 0.25rem 1.5rem;
    font-size: 1rem;
  }

  .body .item .right .icon,
  .body .item.assignment .right .icon {
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }

  .body .item .right .separator {
    height: 17px;
  }

  .body .item .right .chapter.type {
    padding: 0.25rem 1.5rem;
  }

  .body .item .right .chapter {
    padding: 0.25rem 1.5rem;
  }

  .body .item .left .open-file-btn {
    padding: 0.25rem 1.5rem;
    font-size: 1rem;
  }

  .body .item.assignment .left .open-assignment-btn {
    padding: 0.25rem 1.5rem;
    font-size: 1rem;
  }

  .body .item .left .watch-video {
    padding: 0.25rem 1.5rem;
    font-size: 1rem;
  }

  .body .item.assignment .center .questions-title,
  .body .item.assignment .center .time-title {
    font-size: 1rem;
  }

  .body .item.assignment .center .questions-count,
  .body .item.assignment .center .time-count {
    font-size: 1rem;
  }

  .body .item .center {
    justify-content: flex-start !important;
    background-color: #e6e6e6;
    padding: 1rem;
  }

  .body .item .right {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .download-app h3 {
    font-size: 0.8rem;
  }
  .download-app .go-to-forum-btn {
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    gap: 0.25rem;
  }
  .body .item .right {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .body .item .left {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 1rem;
  }
}

@media (max-width: 576px) {
  .download-app {
    padding: 0.75rem;
    gap: 0.5rem;
  }
  .download-app h3 {
    font-size: 0.7rem;
  }
  .download-app .go-to-forum-btn {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
  .body .item.lecture,
  .body .item.assignment .con {
    padding: 0.5rem;
  }

  .body {
    padding: 2rem 0;
    margin: 3rem 0;
    border: none;
    border-radius: 0;
  }

  .body .head h2 {
    font-size: 1rem;
    padding: 0;
    border: none;
    border-radius: 0;
  }

  .accordion-collapse {
    border: none !important;
    border-radius: 0 !important;
  }

  .accordion-body {
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
  }

  .accordion-header {
    border-radius: 0 !important;
  }

  .body .item .right .title-lecture-container {
    font-size: 1rem;
  }

  .body .item .right .icon,
  .body .item.assignment .right .icon {
    width: 30px;
    height: 30px;
    font-size: 0.6rem;
  }

  .body .item .right .chapter.type,
  .body .item .right .chapter {
    padding: 0.25rem 1rem;
  }

  .body .item .right .chapter,
  .body .item .left button,
  .body .item .left .open-file-btn,
  .body .item.assignment .left .open-assignment-btn,
  .body .item .left .watch-video {
    padding: 0.25rem 1rem;
    font-size: 0.6rem;
  }

  .body .item.assignment .center {
    padding: 0.5rem;
    text-align: center;
  }

  .body .item.assignment .center,
  .body .item.assignment .center .time,
  .body .item.assignment .center .questions {
    gap: 0.25rem;
  }

  .body .item .right .sub-description,
  .body .item.assignment .center .questions-title,
  .body .item.assignment .center .time-title,
  .body .item.assignment .center .questions-count,
  .body .item.assignment .center .time-count {
    font-size: 0.6rem;
  }
}

@media (max-width: 767px) {
  .content {
    padding: 0 1rem;
  }
  .head h2 {
    padding: 0.5rem 1rem !important;
    /* width: 80%; */
    border-radius: 5rem !important;
  }
}
</style>
