<template>
  <div class="container">
    <div class="profile-box-info-container">
      <!-- user image -->
      <div class="profile-box-image">
        <img
          src="../../../assets/user-profile.png"
          alt="user profile"
          class="profile-image"
        />
        <div class="rank-score-container">
          <div class="rank">
            <BsAwardFill />
            <h4 class="text-center">
              {{ data?.rank }}
            </h4>
          </div>
          <div class="score">
            <BsCoin />
            <h4 class="text-center">
              {{ data?.points }}
            </h4>
          </div>
        </div>
      </div>
      <!-- user info -->
      <div class="profile-box-info">
        <template v-if="data">
          <div class="profile-box-info-item">
            <h3>{{ data.name }}</h3>
          </div>
          <div class="profile-box-info-item">
            <a>
              <BxSolidPhone class="d-lg-block d-none" />
              <PhBoldPhoneDisconnect class="d-lg-none" />
              <span dir="rtl"> {{ data.user__username }} </span>
            </a>
          </div>
          <div class="profile-box-info-item">
            <a>
              <McGovernmentLine />
              <span dir="rtl">{{ data.government }}</span>
            </a>
          </div>
          <div class="profile-box-info-item">
            <a>
              <CoEducation />
              <span dir="rtl">{{ data.type_education__name }}</span>
            </a>
          </div>
          <div class="profile-box-info-item">
            <a>
              <CoEducation />
              <span dir="rtl">{{ data.year__name }}</span>
            </a>
          </div>
          <!-- <div v-if="data.by_code" class="profile-box-info-item ">
            <a>
              <MdSharpVerified class="verified-icon" />
              <span dir="rtl"> طالب سنتر </span>
            </a>
          </div> -->
          <div v-if="data.by_code" class="profile-box-info-item">
            <a>
              <BsPersonVideo2 />
              <span dir="rtl"> {{ data.code }}</span>
            </a>
          </div>
          <div class="profile-box-info-item w-50">
            <div v-if="data.by_code" class="online-center-badge">سنتر</div>
            <div v-else class="online-center-badge">اونلاين</div>
          </div>
          <div class="profile-box-info-item">
            <button
              @click="send_center_code"
              v-if="!data.by_code"
              class="add-center-code"
            >
              لو أنت طالب سنتر دخل كود id سنتر
            </button>
          </div>
        </template>
        <div v-else class="profile-box-info-item placeholder-glow">
          <span class="placeholder col-7"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-4"></span>
          <span class="placeholder col-6"></span>
          <span class="placeholder col-8"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../../services/config";
import {
  BxSolidPhone,
  PhBoldPhoneDisconnect,
  McGovernmentLine,
  CoEducation,
  BsPersonVideo2,
  BsAwardFill,
  BsCoin,
} from "@kalimahapps/vue-icons";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "UserInfoComponent",
  props: ["data", "refresh"],
  components: {
    BxSolidPhone,
    PhBoldPhoneDisconnect,
    McGovernmentLine,
    CoEducation,
    BsPersonVideo2,
    BsAwardFill,
    BsCoin,
  },
  methods: {
    send_center_code() {
      Swal.fire({
        title: " اكتب كود السنتر ",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "اضافه",
        confirmButtonColor: "#27ae60",
        cancelButtonText: " الغاء ",
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          axios
            .post(
              `${config.baseURL}student/student-sign-code`,
              {
                code: login,
              },
              {
                headers: {
                  auth: this.$store.state.userToken,
                },
              }
            )
            .then(() => {
              this.refresh();
              Swal.fire({
                text: "تم اضافه الكود بنجاح",
                icon: "success",
              });
            })
            .catch((err) => {
              console.log(err.message);
              let msg = "";
              switch (err.response.status) {
                case 406:
                  msg = "لا يمكن التسجيل بهذا الكود ";
                  break;
                case 404:
                  msg = "هذا الكود غير موجود";
                  break;
                default:
                  msg = " حدث خطاء  ";
                  break;
              }
              Swal.fire({
                text: msg,
                icon: "error",
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
  },
};
</script>

<style scoped>
.profile-box-info-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 3rem 2rem;
  border-radius: 50px;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 #00000010;
  gap: 2rem;
  overflow: hidden;
}

.profile-box-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.profile-box-image img.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

button.profile-image-button {
  background-color: #0099ff;
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 35px;
  cursor: pointer;
  border: none;
  outline: none;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
}

button.profile-image-button:hover {
  background-color: #2abf7a;
}

.profile-box-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 50%;
}

.profile-box-info .profile-box-info-item {
  width: 100%;
}
.profile-box-info .profile-box-info-item a {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.profile-box-info .profile-box-info-item span {
  color: #000;
  font-size: 1.25rem;
  font-weight: 500;
}
body.dark .profile-box-info .profile-box-info-item span {
  color: #fff;
}
.profile-box-info .profile-box-info-item h3 {
  font-size: 2.25rem;
  font-weight: 700;
  color: #000;
  margin: 0;
}

.profile-box-info .profile-box-info-item svg {
  font-size: 1.25rem;
  color: #000;
}

.profile-box-info .profile-box-info-item a {
  font-size: 1.25rem;
  font-weight: 300;
  color: #00000080;
  text-decoration: none;
}

.online-center-badge {
  padding: 8px 15px;
  border-radius: 50px;
  text-align: center;
  background-color: #2abf7950;
  border: 1px solid #2abf7a;
  color: #1e1e1e;
  font-weight: bold;
}

body.dark .add-center-code {
  color: #fff;
}
.add-center-code {
  all: unset;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 8px;
  text-align: center;
  background-color: #2abf7950;
  color: #1e1e1e;
  /* box-shadow: 0 5px #1e1e1e; */
  transition: all 0.5s;
  border: 1px solid #2abf7a;
  font-weight: bold;
  font-size: 1.25rem;
}

/* .add-center-code:hover {
  background-color: #27ae60;
  color: #fff;
} */

.verified-icon {
  color: rgb(30 64 175 / 1) !important;
}
/* Rank and Score */

.rank-score-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 3rem;
  overflow: hidden;
}
.rank-score-container svg {
  font-size: 4rem;
}
.rank-score-container .score,
.rank-score-container .rank {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
}
.rank-score-container .rank svg {
  /* color: #b8860b; */
  color: #ffd700 !important;
}
.rank-score-container .score svg {
  /* color: #f4c430; */
  color: #d4af37;
}
.rank-score-container h4 {
  color: #000000;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
}
/* Dark Mode */
body.dark .online-center-badge {
  color: #fff;
}
body.dark .verified-icon {
  color: #fff !important;
}
body.dark .profile-box-info-container {
  background-color: #1d4955;
}
body.dark .profile-box-info-container svg,
body.dark .profile-box-info-container h3 {
  color: #fff;
}
body.dark .profile-box-info-container a {
  color: #ffffff80;
}
body.dark .rank-score-container .score svg {
  color: #ffffff;
}
body.dark .rank-score-container h4 {
  color: #ffffff;
}
/*  Media queries */
@media screen and (max-width: 992px) {
  .profile-box-info-container {
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
  }

  .profile-box-image img.profile-image {
    width: 100px;
    height: 100px;
  }

  .profile-box-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  .profile-box-info button.profile-image-button {
    margin-top: 1rem;
  }

  .profile-box-info .profile-box-info-item a {
    /* flex-direction: row-reverse; */
    gap: 0.5rem;
  }

  .profile-box-info .profile-box-info-item h3 {
    font-size: 1.5rem;
  }

  .profile-box-info .profile-box-info-item a,
  .profile-box-info .profile-box-info-item svg {
    font-size: 1rem;
  }
}
@media (max-width: 575px) {
  .rank-score-container {
    gap: 1.5rem;
  }
  .rank-score-container svg {
    font-size: 3rem;
  }
  .add-center-code {
    font-size: 1rem;
    padding: 5px 10px;
  }
  .profile-box-info-container {
    padding: 3rem 1rem;
  }
}
</style>
