<template>
  <div class="header" @mouseover="playVideo" @mouseleave="pauseVideo">
    <div class="left">
      <img :src="course?.cover" :alt="course?.name" />
      <!-- <YoutubePromoComponent
        v-if="course?.promo_video != null"
        :is_hover="is_hover"
      /> -->
    </div>
    <div class="right">
      <h1 class="title">
        {{ course?.name }}
      </h1>
      <p class="description">
        {{ course?.description }}
      </p>
      <div class="description d-flex">
        <FaSchoolFlag />
        <p class="m-0 me-2">{{ course?.year__name }}</p>
      </div>
      <div class="dates">
        <div class="date">
          <span class="date-title">
            <PhCalendarBlank />
            تاريخ انشاء الكورس
          </span>
          <span class="date-date">{{ getHumanDate(course?.created) }}</span>
        </div>
        <div class="separator"></div>
        <div class="date">
          <span class="date-title">
            <PhCalendarBlank />
            آخر تحديث للكورس
          </span>
          <span class="date-date">
            {{
              getHumanDate(course?.updated) === "Invalid Date"
                ? "لم يتم التحديث"
                : getHumanDate(course?.updated)
            }}
          </span>
        </div>
      </div>
      <div class="price-container">
        <span v-if="course?.free === true" class="price">
          مجاني
          <IoOutlinePricetag />
        </span>
        <span v-if="course?.has_subscription" class="price">
          {{ course?.discounted_price }}
          <IoOutlinePricetag />
        </span>
        <span
          class="price"
          :class="{
            free: course?.free || course?.has_subscription,
          }"
        >
          {{ course?.price }} ج
          <IoOutlinePricetag />
        </span>
      </div>
      <div class="buttons">
        <!-- <button class="start-course-btn">بداء الكورس</button> -->
        <h2>قم بتحميل التطبيق لتتمكن من مشاهدة الكورس</h2>
        <a
          class="go-to-forum-btn"
          :href="downloadLink"
          target="_blank"
          rel="noopener noreferrer"
          role="button"
        >
          <AkDownload />
          حمل التطبيق
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IoOutlinePricetag,
  PhCalendarBlank,
  FaSchoolFlag,
  AkDownload,
} from "@kalimahapps/vue-icons";
// import YoutubePromoComponent from "../YoutubePromo/YoutubePromoComponent.vue";
import { watchEffect } from "vue";
import Swal from "sweetalert2";

export default {
  name: "CourseContentHeaderComponent",
  components: {
    IoOutlinePricetag,
    PhCalendarBlank,
    FaSchoolFlag,
    // YoutubePromoComponent,
    AkDownload,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    getHumanDate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      is_hover: false,
      downloadLink: "",
    };
  },
  mounted() {
    watchEffect(() => {
      this.downloadLink = navigator.userAgent.includes("Android")
        ? "https://play.google.com/store/apps/details?id=com.easytech.easy_player_app&pcampaignid=web_share"
        : navigator.userAgent.includes("iOS") ||
          navigator.userAgent.includes("iPhone")
        ? "https://apps.apple.com/eg/app/easy-player/id6739153042"
        : navigator.userAgent.includes("Win")
        ? //  ||
          //   navigator.userAgent.includes("Mac") ||
          //   navigator.userAgent.includes("Linux")
          "https://d2pez1z0slib5f.cloudfront.net/easyplayer.exe"
        : Swal.fire({
            title: "تنبيه",
            text: "التطبيق متاح فقط على Android و iOS و Windows",
            icon: "warning",
            confirmButtonText: "حسنا",
          });
    });
  },
  watch: {},
  methods: {
    playVideo() {
      this.is_hover = true;
    },
    pauseVideo() {
      this.is_hover = false;
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: calc(100vh - 188px);
  overflow: hidden;
  position: relative;
  z-index: 0;
  /* padding-bottom: 1rem; */
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #00000000, #00000000),
    linear-gradient(
      270deg,
      #000000 0%,
      rgb(0 0 0) 36.5%,
      rgb(0 0 0 / 56%) 54.52%,
      rgb(0 0 0 / 0%) 73.18%
    );
  z-index: 1;
}

.header .left {
  width: calc(60% - 2rem);
  height: calc(100vh - 179px);
  /* width: 60%; */
  overflow: hidden;
  z-index: 0;
  position: relative;
}

.header .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-fit: fill;
}

.header .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 2rem;
  width: calc(40% - 2rem);
  overflow: hidden;
  z-index: 2;
  margin-top: 0rem;
}

.header .title {
  font-size: 3rem;
  font-weight: 500;
  text-align: right;
  color: #ffffff;
  margin: 0;
}

.header .description {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 300;
  text-align: right;
  color: #ffffffcc;
}

.header .dates {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.header .separator {
  height: 45px;
  border-left: 0.8px solid #ffffff;
}

.header .date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.25rem;
}

.header .date-title {
  font-size: 0.8rem;
  font-weight: 300;
  text-align: right;
  color: #ffffffcc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.header .date-title svg {
  font-size: 1rem;
}

.header .date-date {
  font-size: 1rem;
  font-weight: 500;
  text-align: right;
  color: #ffffffcc;
}

.header .price-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.header .price {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  border-radius: 3rem;
}

.header .price.free {
  text-decoration: line-through;
  color: #ffffff80;
}

.header .buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.header .buttons h2 {
  font-size: 1.25rem;
  color: #ffffff;
  font-weight: 900;
  text-align: center;
}

.header .buttons button,
.header .buttons a {
  padding: 0.5rem 2rem;
  min-width: 100px;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  outline: none;
  border-radius: 3rem;
  cursor: pointer;
  text-decoration: none;
}

.header .start-course-btn {
  font-weight: 500;
  background-color: #038c4c;
  border: 1px solid #038c4c;
}

.header .go-to-forum-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: 900;
  background-color: #038c4c;
  border: 1px solid #038c4c;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.header .go-to-forum-btn:hover {
  background-color: #ffffff;
  color: #038c4c;
  transition: all 0.3s ease-in-out;
}

.left.show iframe {
  opacity: 0 !important;
}

/* Media Queries */
@media (max-width: 1200px) {
  .header {
    flex-direction: column;
    gap: 0;
    padding-bottom: 1rem;
    height: auto;
  }

  .header::before {
    background: linear-gradient(0deg, #00000060, #00000060),
      linear-gradient(
        0deg,
        #000000 0%,
        rgb(0 0 0) 0%,
        rgb(0 0 0 / 56%) 54.52%,
        rgb(0 0 0 / 0%) 73.18%
      );
  }

  .header .left {
    width: 100%;
    height: auto;
    /* z-index: 1; */
  }

  .header .right {
    width: 100%;
    padding: 1rem;
    margin-top: 0;
  }

  .header .title {
    font-size: 2rem;
  }

  .header .description {
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  /* .header .left {
    height: 300px;
  } */

  .header .title {
    font-size: 1.75rem;
  }

  .header .description {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  /* .header::before {
    background: linear-gradient(0deg, #00000080, #00000080),
      linear-gradient(
        0deg,
        #000000 59%,
        rgb(0 0 0) 36.5%,
        rgb(0 0 0 / 56%) 54.52%,
        rgb(0 0 0 / 0%) 73.18%
      );
  } */

  /* .header .left {
    height: 400px;
  } */

  .buttons {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .buttons button {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .header .title {
    font-size: 1.5rem;
  }

  .header .description {
    font-size: 0.8rem;
  }

  .header .dates {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .header .date {
    width: 100%;
  }

  .header .separator {
    display: none;
  }

  .header .buttons {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .header .buttons button {
    width: 100%;
  }
}
</style>
