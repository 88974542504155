<template>
  <div class="container">
    <div class="courses-statistics-box-container">
      <template v-if="!is_loading">
        <!-- lessons -->
        <div
          class="courses-statistics-box-item"
          v-for="category in analysis_data"
          :key="category.question_category_id"
        >
          <h3 class="title">
            {{ category?.question_category }}
          </h3>
          <div class="statistics-prog-container">
            <span
              class="statistics-prog"
              :style="{
                width:
                  Math.round(
                    (category?.correct / category?.total) * 100
                  ).toFixed(2) + '%',
              }"
            ></span>
          </div>
          <div class="bottom justify-content-between">
            <span class="video">
              {{ category?.correct }}
              سؤال صح من
              {{ category?.total }}
            </span>
            <span class="average">
              {{
                Math.round((category?.correct / category?.total) * 100).toFixed(
                  2
                )
              }}
              %
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnswersStatisticsComponent",
  components: {},
  props: {
    analysis_data: {
      type: Array,
      required: true,
    },
    is_loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  created() {
    console.log(this.analysis_data);
  },
};
</script>

<style scoped>
.courses-statistics-box-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 50px;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 #00000010;
  gap: 2rem;
  overflow: hidden;
}

.statistics-prog-container {
  position: relative;
  background-color: #d9d9d9;
  border-radius: 23px;
  width: 100%;
  height: 11px;
  overflow: hidden;
}

.statistics-prog {
  position: relative;
  display: block;
  width: 90%;
  height: 11px;
  border-radius: 23px;
  background-color: #2abf7a;
  opacity: 0;
  animation: progress 1.5s ease-in-out forwards;
}

/* Animation to show the progress bar */
@keyframes progress {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
  }
}

.courses-statistics-box-item {
  width: 100%;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.video,
.test,
.average {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.video svg,
.test svg {
  font-size: 1rem;
}

.video svg {
  color: #e71c39;
}

.test svg {
  color: #9747ff;
}

.average {
  background-color: #000;
  color: #fff;
  width: 4rem;
  height: 2rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Dark Mode */
body.dark .courses-statistics-box-container {
  background-color: #1d4955;
}

body.dark .average,
body.dark .statistics-prog-container {
  background-color: #13323a;
}

body.dark .title,
body.dark .bottom span {
  color: #fff;
}

/*  Media queries */
@media screen and (max-width: 992px) {
  .courses-statistics-box-container {
    width: 100%;
  }

  .title {
    font-size: 1rem;
  }
}
</style>
