<template>
  <div class="accordion-item">
    <h2
      @click="lazy_lessons"
      class="accordion-header mb-3"
      :id="'flush-heading' + index"
    >
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="'#flush-collapse' + index"
        aria-expanded="false"
        :aria-controls="'flush-collapse' + index"
      >
        <div class="icon-name">
          <BxCategory />
          <div>
            <p class="unit-name">
              {{ unit?.name }}
            </p>
            <p class="mt-2">
              {{ unit?.description }}
            </p>
          </div>
        </div>
      </button>
    </h2>
    <div
      :id="'flush-collapse' + index"
      class="accordion-collapse collapse"
      :aria-labelledby="'flush-heading' + index"
      :data-bs-parent="'#accordionFlushExample' + index"
    >
      <div
        v-if="unit_content.length > 0 && is_loading == false"
        class="accordion-body"
      >
        <LessonBoxComponent
          v-for="content in unit_content"
          :content="content"
          :key="content?.id"
          :has_subscription="has_subscription"
          :course_id="course_id"
        />
      </div>

      <div
        class="accordion-body"
        v-else-if="unit_content.length == 0 && is_loading == false"
      >
        <p class="text-center">لا يوجد محتوي في الوحده</p>
      </div>

      <div
        class="accordion-body d-flex flex-column align-items-center justify-content-center"
        v-else-if="is_loading"
      >
        <p>جاري تحميل المحتوي</p>
        <span class="spinner-border"></span>
      </div>
    </div>
  </div>
</template>
<script>
import config from "@/services/config";
import { BxCategory } from "@kalimahapps/vue-icons";
import axios from "axios";
import LessonBoxComponent from "../LessonBoxComponent/LessonBoxComponent.vue";
export default {
  name: "UnitBoxComponent",
  props: ["unit", "index", "has_subscription", "course_id"],
  components: {
    BxCategory,
    LessonBoxComponent,
  },
  data() {
    return {
      unit_content: [],
      is_loading: false,
      data_fetched: false,
    };
  },
  methods: {
    lazy_lessons() {
      if (!this.data_fetched) {
        this.is_loading = true;
        axios
          .get(`${config.baseURL}course/unit-content/${this.unit.id}`, {
            headers: {
              auth: this.$store.state.userToken,
            },
          })
          .then((res) => {
            console.log(res.data);
            this.unit_content = res.data;
          })
          .catch((err) => {
            console.log(err.message);
          })
          .finally(() => {
            this.is_loading = false;
            this.data_fetched = true;
          });
      }
    },
  },
};
</script>
<style scoped>
.accordion-button {
  border-width: 0.5px 0.5px 5px 0.5px !important;
  border-style: solid !important;
  border-color: #000000 !important;
  border-radius: 1rem !important;
  overflow: hidden;
}

.accordion-button .icon-name {
  display: flex;
  align-items: center;
}

.accordion-button .icon-name .unit-name {
  font-size: 2rem;
  font-weight: bold;
}

.accordion-button .icon-name > div p {
  margin: 0;
}

.accordion-button .icon-name svg {
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;
  color: #e71c39;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #000000 !important;
}

.accordion-button::after {
  margin-left: 0 !important;
  background-image: var(--bs-accordion-btn-icon) !important;
}
body.dark .accordion-button::after {
  margin-left: 0 !important;
  --bs-accordion-btn-icon: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e" !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-body {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* min-width: 768px; */
}

body.dark .accordion-header,
body.dark .accordion-button,
body.dark .accordion-body {
  background-color: #13323a !important;
  color: #ffff !important;
}

::v-deep(body.dark .accordion-body) {
  background-color: #183a42 !important;
  color: #ffff !important;
}
::v-deep(body.dark .accordion-body .item) {
  background-color: #13323a !important;
  color: #ffff !important;
}
/* .accordion-collapse.collapse {
  overflow: auto;
  scroll-behavior: smooth;
} */
/* scroll */
/* .accordion-collapse.collapse::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.accordion-collapse.collapse::-webkit-scrollbar-thumb {
  background-color: #e71c39;
  border-radius: 0.5rem;
}
.accordion-collapse.collapse::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-radius: 0.5rem;
} */
@media (max-width: 767px) {
  .unit-name {
    font-size: 1.2rem !important;
  }
}
</style>
